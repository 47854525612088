<template>
  <v-card
    color="rgba(253, 253, 253, 0.95)"
    class="ma-3 pa-3"
  >
    <v-row>
      <v-col>
        <div class="text-subtitle-2">
          *Информация по отсутствую на рабочем месте появляется на следующий день после утверждения табеля
        </div>
      </v-col>
    </v-row>
    <v-toolbar
      flat
      color="rgba(253, 253, 253, 0.95)"
      class="ml-0"
    >
      <v-menu
        ref="menu"
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="20"
        transition="scale-transition"
        offset-y
        width="290px"
      >
        <template #activator="{ on }">
          <v-responsive max-width="130">
            <v-text-field
              v-model="monthFormatted"
              prepend-icon="mdi-calendar"
              readonly
              v-on="on"
            />
          </v-responsive>
        </template>
        <v-date-picker
          v-model="perMonth"
          type="month"
          no-title
          scrollable
          locale="ru-ru"
          @input="update(perMonth)"
        />
      </v-menu>
      <v-spacer />
      <v-btn
        :loading="loading"
        :disabled="loading"
        class="mr-0"
        color="#999"
        @click="sendMail"
      >
        <v-icon
          color="white"
        >
          mdi-email
        </v-icon>
        <span class="d-none d-sm-flex pl-2">
          Отправить отчет
        </span>
      </v-btn>
    </v-toolbar>
    <h3 class="text-h5 font-weight-light  mb-2">
      Сотрудники
    </h3>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="employees"
      :sort-by="['fio']"
      :items-per-page="5"
      class="elevation-1"
      single-select
      :loading="tableLoading"
      :footer-props="{ itemsPerPageText:'Строк на странице:' }"
      @click:row="select"
    >
      <template #item.datestr="{ item }">
        <div class="ml-4">
          {{ item.mistmatchcount }} / {{ item.blank }}
        </div>
      </template>
    </v-data-table>
    <calendar
      :start="start"
      :events="events"
    />
  </v-card>
</template>

<script>
  import calendar from './components/calendar'
  import { mapGetters } from 'vuex'

  export default {
    name: 'Timex',
    components: {
      calendar,
    },
    data () {
      return {
        loading: false,
        empListToSend: [],
        newDetails: null,
        tableLoading: true,
        pagination: {
          sortBy: 'Empid',
        },
        menu1: false,
        menu2: false,
        notAllBlank: false,
        monthFormatted: this.formatDate2(new Date().toISOString().substr(0, 7)),
        dateFromFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
        dateToFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
        findEmpid: 18493,
        type: 'month',
        // start: '2019-03-01',
        end: '2019-03-06',
        typeOptions: [
          { text: 'Day', value: 'day' },
          { text: '4 Day', value: '4day' },
          { text: 'Week', value: 'week' },
          { text: 'Month', value: 'month' },
          { text: 'Custom Daily', value: 'custom-daily' },
          { text: 'Custom Weekly', value: 'custom-weekly' },
        ],
        selected: [],
        headers: [
          {
            text: 'Номер',
            value: 'empid',
            align: 'center',
            width: '100px',
          },
          {
            text: 'ФИО',
            align: 'left',
            sortable: true,
            value: 'fio',
          },
          {
            text: 'Количество несовпадений(Всего/Заполнено)',
            align: 'left',
            value: 'datestr',
            sortable: false,
          },
        ],
      }
    },
    computed: {
      ...mapGetters({
        EmpListString: 'EmpListString',
        isAuthenticated: 'isAuthenticated',
        EmpDept: 'EmpDept',
        foremanFIO: 'foremanFIO',
        empid: 'empid',
      }),
      perMonth: {
        get: function () {
          return this.dateFrom.split('-')[0] + '-' + this.dateFrom.split('-')[1]
        },
        set: function (newValue) {
          // sole.log(newValue)
          this.dateTo = new Date(newValue.split('-')[0], newValue.split('-')[1], 1).toISOString().substr(0, 10)
          this.dateFrom = newValue + '-01'
        },
      },
      start: {
        get: function () {
          return this.$store.getters.calendarStart
        },
        set: function (newValue) {
          this.$store.dispatch('setCalendarStart', newValue)
        },
      },
      dateFrom: {
        get: function () {
          return this.$store.getters.dateFrom
        },
        set: function (newValue) {
          this.$store.dispatch('setDateFrom', newValue)
        },
      },
      dateTo: {
        get: function () {
          return this.$store.getters.dateTo
        },
        set: function (newValue) {
          this.$store.dispatch('setDateTo', newValue)
        },
      },
      employees: {
        get: function () {
          return this.$store.getters.employees
        },
        set: function (newValue) {
          this.$store.dispatch('updateEmpList', newValue)
        },
      },
      events: {
        get: function () {
          return this.$store.getters.events
        },
        set: function (newValue) {
          this.$store.dispatch('updateEvents', newValue)
        },
      },

    },
    watch: {
      dateFrom (val) {
        this.dateFromFormatted = this.formatDate(this.dateFrom)
        this.monthFormatted = this.formatDate2(this.dateFrom)
      },
      dateTo (val) {
        this.dateToFormatted = this.formatDate(this.dateTo)
      },
    },
    created () {
      var d = new Date()
      console.log(d.getDate())
      if (d.getDate() < 15) {
        d.setMonth(d.getMonth() - 1)
      }
      d.setDate(1)
      var dt = new Date()
      if (dt.getDate() < 15) {
        dt.setMonth(dt.getMonth())
      } else { dt.setMonth(dt.getMonth() + 1) }
      dt.setDate(0)
      this.dateFrom = d.toISOString().substr(0, 10)
      this.dateTo = dt.toISOString().substr(0, 10)
      var toSend = { dateTo: this.dateTo, dateFrom: this.dateFrom }
      this.$store.dispatch('getTimeSheetStates')
      this.$store.dispatch('getEmpl', toSend).then(() => {
        this.$store.dispatch('getEmployeesMistmatchList', { Datefrom: this.dateFrom, Dateto: this.dateTo, empListString: this.EmpListString, EmpDept: this.EmpDept }).then(() => { this.tableLoading = false })
        this.$store.dispatch('setLoading', false)
      }).catch((error) => {
        console.log('error:', error)
        // this.$store.dispatch('authLogout')
      })
    },
    methods: {
      sendMail () {
        var toSend = {}
        this.loading = true
        this.empListToSend = []
        this.notAllBlank = false
        this.employees.forEach((x) => {
          // console.log(x)
          if (x.mistmatchcount > 0) { this.empListToSend.push(x.empid) }
          if (x.mistmatchcount != x.blank) {
            this.notAllBlank = true
            console.log('')
          }
        })
        if (this.notAllBlank) {
          this.$store.dispatch('setSnackbar', { state: true, text: 'Не все причины несовпадений заполнены', color: 'warning', direction: 'top center' })
          this.loading = false
        } else {
          if (this.empListToSend.length > 0) {
            console.log('Отправляем почту')
            toSend = { empid: this.empid, foremanFIO: this.foremanFIO.fioshort, emlList: this.empListToSend, dateTo: this.dateTo, dateFrom: this.dateFrom, RegionName: this.foremanFIO.regionname }
            console.log(toSend)
            this.$store.dispatch('SendMail', toSend).then(() => {
              this.$store.dispatch('setSnackbar', { state: true, text: 'Ваш отчет отправлен в департамент по работе с персоналом.', color: 'info', direction: 'top center' })
              this.loading = false
            })
          } else {
            toSend = { foremanFIO: this.foremanFIO.fio, dateFrom: new Date(this.dateFrom), RegionName: this.foremanFIO.regionname }
            this.$store.dispatch('SendEmptyMail', toSend).then(() => {
              this.$store.dispatch('setSnackbar', { state: true, text: 'У вас нет сотрудников с несовпадениями. Ваш отчет отправлен в департамент по работе с персоналом.', color: 'warning', direction: 'top center' })
            })
            this.loading = false
            console.log('Нет сотрудников с несовпадениями')
          }
        }
      },
      update (value) {
        this.tableLoading = true
        this.$refs.menu.save(value)
        this.start = this.dateFrom
        this.$store.dispatch('getEmployeesMistmatchList', { Datefrom: this.dateFrom, Dateto: this.dateTo, empListString: this.EmpListString, EmpDept: this.EmpDept }).then(() => { this.tableLoading = false })
        if (this.selected.length > 0) {
          this.events = []
          this.getEmpTime({ empid: this.selected[0].empid, fio: value.fio })
        }
      },
      select (selectedItem) {
        this.tableLoading = true
        this.selected = []
        this.events = []
        this.employees.forEach(item => {
          if (item.empid == selectedItem.empid) {
            this.selected.push(item)
          }
        })
        console.log(selectedItem)
        this.getEmpTime({ empid: selectedItem.empid, fio: selectedItem.fio })
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      formatDate2 (date) {
        if (!date) return null

        const [year, month] = date.split('-')
        return `${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [day, month, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      getEmpTime (x) {
        this.$store.dispatch('getEmpList', { dateFrom: this.dateFrom, dateTo: this.dateTo, findEmpid: x.empid, EmpDept: this.EmpDept }).then((value) => {
          if (value.lenght > 0) {
            console.log('getEmplist', value)
            this.events = []
            this.start = value[0].datestr
            value.forEach((v, i) => {
              if (v.TSSID == 0) {
                this.events.push(
                  {
                    Empid: v.empid,
                    title: '[Р] ' + x.fio,
                    date: v.datestr,
                    color: 'green darken-1',
                    open: false,
                    type: v.tssid,
                    id: i,
                  }
                )
              }
              if (v.TSSID == 4) {
                this.events.push(
                  {
                    Empid: v.empid,
                    title: '[В] ' + x.fio,
                    date: v.datestr,
                    color: 'blue darken-2',
                    open: false,
                    type: v.tssid,
                    id: i,
                  }
                )
              } else if (v.TSSID == 0 && !v.presence && v.explanatory) {
                this.events.push(
                  {
                    Empid: v.empid,
                    title: '[не приходил по скуд] ' + x.fio,
                    details: v.explanatory,
                    details_long: v.explanatory_long,
                    presence: true,
                    date: v.datestr,
                    color: 'tskud',
                    open: false,
                    type: v.tssid,
                    id: i + 5000,
                  }
                )
                this.start = v.datestr
              } else if (v.TSSID == 0 && !v.presence) {
                this.events.push(
                  {
                    Empid: v.empid,
                    title: '[не приходил по скуд] ' + x.fio,
                    details: v.explanatory,
                    details_long: v.explanatory_long,
                    presence: true,
                    date: v.datestr,
                    color: 'red darken-3',
                    open: false,
                    type: v.tssid,
                    id: i + 5000,
                  }
                )
                this.start = v.datestr
              } else if (v.TSSID > 0) {
                this.events.push(
                  {
                    Empid: v.empid,
                    title: '[О] ' + x.fio,
                    date: v.datestr,
                    color: 'orange darken-1',
                    open: false,
                    type: v.tssid,
                    id: i,
                  }
                )
              }
            })
            this.tableLoading = false
          }
        })
      },
    },
  }
</script>

<style scoped>
  /deep/ .v-toolbar__content {
    padding: 0px !important;
  }
</style>
