<template>
  <span>
    <div class="text-h5 font-weight-light  mt-5 mb-1">{{ monthAndYear(start) }}</div>
    <v-col>
      <v-sheet height="800">
        <v-calendar
          ref="calendar"
          v-model="start"
          color="green"
          locale="ru-RU"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
        >
          <template #day="{ date }">
            <template v-for="(event, i) in eventsMap[date]">
              <div
                v-if="!event.presence"
                :key="i"
                class="my-event no-click"
                :class="event.color"
                v-html="event.title"
              />
              <v-menu
                v-if="event.presence"
                :key="event.id"
                v-model="event.open"
                :close-on-click="false"
                offset-y
                top
                :close-on-content-click="false"
                nudge-left="100%"
                nudge-top="10"
              >
                <template #activator="{ on }">
                  <div
                    v-if="event.presence"
                    v-ripple
                    class="my-event"
                    :class="event.color"
                    v-on="on"
                    @click="snapData(event)"
                    v-html="event.title"
                  />
                </template>
                <v-card
                  color="white"
                  min-width="350px"
                  max-width="450px"
                  class="calPopUp"
                  text
                >
                  <v-toolbar
                    color="primary"
                    dark
                  >
                    <v-toolbar-title v-html="event.title" />
                    <v-spacer />
                    <v-btn
                      icon
                      @click="event.open=false"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-toolbar>
                  <v-form
                    ref="form"
                    v-model="form"
                  >
                    <v-card-title primary-title>
                      {{ event.date }} {{ timeSheetsStates[event.type].desc }} день
                      <v-col
                        class="ma-2"
                        cols="12"
                      >
                        <v-select
                          v-model="newDetails"
                          :items="exp_note"
                          item-text="name"
                          item-value="id"
                          label="Причина неявки:"
                          @input="checkData(event)"
                        />
                        <v-textarea
                          v-if="newDetails==10"
                          v-model="newDetails_long"
                          rows="1"
                          auto-grow
                          :rules="[rules.required]"
                          @input="checkData(event)"
                        />
                      </v-col>
                    </v-card-title>
                    <v-card-actions>

                      <v-btn
                        text
                        color="secondary"
                        @click="closeData(event)"
                      >
                        Закрыть
                      </v-btn>
                      <v-btn
                        :disabled="saveDisable||!form"
                        color="success"
                        class="ml-auto"
                        @click="editItem(event)"
                      >
                        Сохранить
                      </v-btn>

                    </v-card-actions>
                  </v-form>

                </v-card>
              </v-menu>
            </template>
          </template>
        </v-calendar>
      </v-sheet>
    </v-col>
  </span>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    data: () => ({
      newDetails: null,
      detailsSnap: null,
      saveDisable: true,
      dialog: false,
      detailsSnap_long: null,
      newDetails_long: null,
      form: false,
      rules: {
        required: v => !!v || 'Это поле необходимо заполнить',
      },
    }),
    computed: {
      ...mapGetters({
        exp_note: 'exp_note',
        EmpListString: 'EmpListString',
      }),
      timeSheetsStates () {
        return this.$store.getters.timeSheetsStates
      },
      dateFrom: {
        get: function () {
          return this.$store.getters.dateFrom
        },
        set: function (newValue) {
          this.$store.dispatch('setDateFrom', newValue)
        },
      },
      dateTo: {
        get: function () {
          return this.$store.getters.dateTo
        },
        set: function (newValue) {
          this.$store.dispatch('setDateTo', newValue)
        },
      },
      events: {
        get: function () {
          return this.$store.getters.events
        },
        set: function (newValue) {
          this.$store.dispatch('updateEvents', newValue)
        },
      },
      start: {
        get: function () {
          return this.$store.getters.calendarStart
        },
        set: function (newValue) {
          this.$store.dispatch('setCalendarStart', newValue)
        },
      },
      // convert the list of events into a map of lists keyed by date
      eventsMap () {
        const map = {}
        this.events.forEach(e => (map[e.date] = map[e.date] || []).push(e))
        return map
      },

    },
    methods: {
      closeData (value) {
        console.log(value)
        value.open = false
      },
      snapData (data) {
        this.events.forEach((item) => { item.open = false })
        this.saveDisable = true
        this.detailsSnap = null
        this.newDetails = null
        this.newDetails_long = null
        this.detailsSnap_long = null
        this.detailsSnap = data.details
        this.detailsSnap_long = data.details_long
        this.newDetails = data.details
        this.newDetails_long = data.details_long
        // console.log( data.id)
      },
      checkData (data) {
        if (this.detailsSnap != this.newDetails || this.detailsSnap_long != this.newDetails_long) {
          this.saveDisable = false
        }
      },
      editItem (value) {
        if (this.newDetails < 10) {
          this.newDetails_long = null
        }
        this.$store.dispatch('UpdateNote', { noteid: this.newDetails, note: this.newDetails_long, DateStr: value.date, empid: value.Empid }).then((x) => {
          this.$store.dispatch('getEmployeesMistmatchList', { Datefrom: this.dateFrom, Dateto: this.dateTo, empListString: this.EmpListString }).then(() => { this.tableLoading = false })
          value.details = this.newDetails
          if (value.details) { value.color = 'tskud' } else { value.color = 'red darken-3' }
          this.saveDisable = true
          setTimeout(() => {
            value.open = false
            this.$store.dispatch('setSnackbar', { state: true, text: 'Запись сохранена', color: 'success', direction: 'top center' })
          }, 1000)
        })
      },
      open (event) {
        alert(event.title)
      },
      monthAndYear (date) {
        var locDate = new Date(date)
        var newDate; var month = locDate.toLocaleString('ru', { month: 'long' })
        newDate = date.split('-')
        newDate = month.charAt(0).toUpperCase() + month.slice(1) + ' ' + newDate[0]
        return newDate
      },
      test (v) {
        console.log('action')
        console.log(v)
      },
    },
  }
</script>

<style>
  .v-application .calPopUp{
    margin: 0;
  }
  div.v-calendar-weekly__day:nth-child(6){
    background-color: #f2fae7;
  }
  div.v-calendar-weekly__day:nth-child(7){
    background-color: #f2fae7;
  }
  div.v-calendar-weekly__head-weekday:nth-child(6){
    background-color: #f2fae7;
  }
  div.v-calendar-weekly__head-weekday:nth-child(7){
    background-color: #f2fae7;
  }
  .my-event.no-click{
    cursor: default
  }
  .tskud{
    text-align: center;
    background: linear-gradient(90deg,rgba(67,160,71,1) 0%, rgba(67,160,71,1) 5%, rgba(198,40,40,1) 7%,rgba(198,40,40,1) 100%);
  }
  .tskud2{
    word-spacing: 20px;
    text-align: center;
    background: linear-gradient(105deg, rgba(59,52,247,1) 0%,rgba(59,52,247,1) 40%, rgba(231,56,39,1) 60%, rgba(231,56,39,1) 100%);
  }
  .partyTime{
    background-color: orange;
  }
  .my-event {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: #1867c0;
    color: #ffffff;
    border: 1px solid #1867c0;
    width: 100%;
    font-size: 12px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
  }
</style>
